import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/image";
import { useState } from "react";

const WrappedImage = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...props}
      onLoadingComplete={handleLoad}
      className={clsx("transition-opacity ease-out", {
        "opacity-0": !isLoaded,
        "opacity-100": isLoaded,
      })}
      loader={cloudflareLoader}
    />
  );
};

export default WrappedImage;
