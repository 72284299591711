import clsx from "clsx";

const PermissionPrompt = ({ friendlyName }) => (
  <div className="space-y-4 rounded-2xl bg-zinc-950 p-8 text-zinc-50">
    <div className={clsx("body-text-sm")}>
      {`Sorry, you need to accept cookies from ${friendlyName} to view this
        content.`}
    </div>
    <div>
      <a
        href="#"
        className={clsx("button button-light")}
        onClick={(e) => {
          e.preventDefault();
          window.Didomi && window.Didomi.notice.show();
        }}
      >
        Open privacy settings
      </a>
    </div>
  </div>
);

export default PermissionPrompt;
