import { useState } from "react";
import Accordion from "./Accordion";
import TextBlock from "./TextBlock";

const BodyAccordion = ({ items }) => {
  // Setup an object where each key is the id of an accordion within items and the value is false
  const [openItem, setOpenItem] = useState(null);

  // Loop through block.accordions and create an array of items for the Accordion component
  const cleansedItems = items.map((accordion) => ({
    key: accordion.id,
    heading: accordion.accordionTitle,
    component: <TextBlock text={accordion.accordionText} noGutter size="sm" />,
    isActive: openItem === accordion.id,
  }));

  return (
    <div className="my-6">
      <Accordion
        items={cleansedItems}
        onClick={(item) => {
          if (!item) {
            setOpenItem(null);
          } else {
            setOpenItem(item.key ?? null);
          }
        }}
      />
    </div>
  );
};

export default BodyAccordion;
