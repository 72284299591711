import Ad from "@/src/ads/components/Ad";
import clsx from "clsx";
import { HTMLElement, parse } from "node-html-parser";
import React from "react";

type TextBlockProps = {
  text: string;
  noGutter?: boolean;
  size?: "xl" | "lg" | "base" | "sm";
  showAds?: boolean;
};

const TextBlock: React.FC<TextBlockProps> = ({
  text,
  noGutter = false,
  size = "lg",
  showAds = false,
}) => {
  const root = parse(text);
  const elements = root.childNodes.filter(
    (node): node is HTMLElement => node instanceof HTMLElement
  );

  const content = elements.reduce((acc: React.ReactNode[], element, index) => {
    acc.push(
      <div
        key={`element-${index}`}
        dangerouslySetInnerHTML={{ __html: element.toString() }}
      />
    );

    if (showAds && (index + 1) % 3 === 0 && index < elements.length - 1) {
      acc.push(
        <Ad key={`ad-${index}`} placementName={`htlad-article_right`} />
      );
    }

    return acc;
  }, []);

  return (
    <div
      className={clsx("TextBlock", "flex justify-center", {
        "px-6 lg:px-12": !noGutter,
        "px-0": noGutter,
      })}
    >
      <div
        className={clsx("TextBlock__content", {
          "body-text-xl": size === "xl",
          "body-text-lg": size === "lg",
          "body-text": size === "base",
          "body-text-sm": size === "sm",
        })}
      >
        {content}
      </div>
    </div>
  );
};

export default TextBlock;
