import clsx from "clsx";
import Target from "components/Target";
import Image from "components/WrappedImage";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useInView } from "react-hook-inview";

const ImageBlock = observer(({ image, caption, showInSidebar, targetLink }) => {
  const { uiStore } = useStores();

  const className = clsx("ImageBlock", {
    "ImageBlock--showInSidebar": showInSidebar,
  });

  const [ref, isVisible] = useInView({ threshold: 1 });

  useEffect(() => {
    if (isVisible && showInSidebar) {
      uiStore.setSidebarImage(image);
      uiStore.setSidebarCaption(caption);
    }
  }, [uiStore, isVisible, caption, image, showInSidebar]);

  if (!image) return null;

  return (
    <div className={className} ref={ref}>
      <div className="ImageBlock__content">
        <Target className="ImageBlock__image" target={targetLink}>
          <Image
            src={image.url}
            width={image.width}
            height={image.height}
            alt={image.title}
            layout="responsive"
          />
        </Target>
        {caption && <div className="ImageBlock__caption">{caption}</div>}
      </div>
    </div>
  );
});

ImageBlock.defaultProps = {
  image: null,
  caption: null,
  showInSidebar: false,
  targetLink: null,
};

export default ImageBlock;
