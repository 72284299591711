import SpotifyPlayer from "react-spotify-player";
import usePermission from "@hooks/usePermission";
import PermissionPrompt from "./PermissionPrompt";

const Player = ({ spotifyUri, view }) => {
  const hasPermission = usePermission("c:spotify-embed");

  if (!hasPermission) return <PermissionPrompt friendlyName="Spotify" />;

  return (
    <div className="Player">
      <div className="Player__embed">
        <SpotifyPlayer
          uri={spotifyUri}
          size={{ width: "100%", height: 80 }}
          view={view}
        />
      </div>
    </div>
  );
};

Player.defaultProps = {
  view: "coverart", // or list
};

export default Player;
