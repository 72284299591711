/* eslint-disable react/prop-types */
import Ad from "@/src/ads/components/Ad";
import usePermission from "@hooks/usePermission";
import clsx from "clsx";
import Callout from "components/Callout";
import ImageBlock from "components/ImageBlock";
import InstagramPost from "components/InstagramPost";
import Player from "components/Player";
import TextBlock from "components/TextBlock";
import VideoBlock from "components/VideoBlock";
import { motion } from "framer-motion";
import { defaultContainerVariants } from "lib/constants";
import { useInView } from "react-hook-inview";
import AboutMobPremiumBlock from "./AboutMobPremiumBlock";
import BodyAccordion from "./BodyAccordion";
import PermissionPrompt from "./PermissionPrompt";

let adPlacement = 0;

const Body = ({ body, premiumPromotion }) => {
  return (
    <div className="Body">
      <div className="Body__blocks">
        {body.map((block) => (
          <Block
            key={block.id}
            block={block}
            adPlacement={adPlacement}
            premiumPromotion={premiumPromotion}
          />
        ))}
      </div>
    </div>
  );
};

const Block = ({ block, premiumPromotion }) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
  });

  const className = clsx("Body__block", `Body__block--${block.typeHandle}`);

  return (
    <motion.div
      ref={ref}
      variants={defaultContainerVariants}
      initial="hidden"
      animate={isVisible ? "show" : "hidden"}
      exit="hidden"
      className={className}
    >
      <Component block={block} premiumPromotion={premiumPromotion} />
    </motion.div>
  );
};

const Component = ({ block, premiumPromotion }) => {
  const hasInstagramPermission = usePermission("c:instagram");

  switch (block.typeHandle) {
    case "callout":
      return <Callout heading={block.heading} text={block.text} />;
    case "text":
      return (
        <div className={clsx("md:px-6")}>
          <TextBlock
            text={block.text}
            size="base"
            noGutter
            showAds={process.env.NEXT_PUBLIC_ADS_PROVIDER === "hashtaglabs"}
          />
        </div>
      );
    case "heading":
      return <Heading heading={block.heading} />;
    case "image":
      const image = block.image?.[0];

      return (
        <ImageBlock
          image={image}
          size={block.size}
          caption={block.caption}
          showInSidebar={block.showInSidebar}
          targetLink={block.targetLink}
        />
      );
    case "video":
      return (
        <VideoBlock
          brightcoveVideoId={block.brightcoveVideoId}
          embed={block.embed}
        />
      );
    case "musicPlayer":
      if (block.musicEntry.length === 0) return null;
      return <Player spotifyUri={block.musicEntry[0].spotifyUri} view="list" />;
    case "instagramPost":
      return hasInstagramPermission ? (
        <InstagramPost postUrl={block.postUrl} embedCode={block.embedCode} />
      ) : (
        <div className="mx-6 mb-6">
          <PermissionPrompt friendlyName="Instagram" />
        </div>
      );
    case "accordionSet":
      return (
        <div className="mx-6 my-6">
          <BodyAccordion items={block.accordions} />
        </div>
      );
    case "ad":
      adPlacement++;
      // Only show these ads on mobile
      return (
        <>
          <Ad
            placementName={
              process.env.NEXT_PUBLIC_ADS_PROVIDER === "freestar"
                ? `mob.co.uk_square_${adPlacement}`
                : `htlad-article_right`
            }
            className="md:hidden"
          />
        </>
      );
    case "premiumPromoWidget":
      return (
        <AboutMobPremiumBlock
          key={block.id}
          blockData={premiumPromotion}
          rounded
          wrapperClass="my-6 -ml-6 -mr-6 w-[calc(100%+3rem)] md:ml-6 md:mr-6 md:w-auto"
        />
      );
    default:
      return null;
  }
};

const Heading = ({ heading }) => (
  <div className={clsx("Body__headingWrapper", "px-0 md:px-6")}>
    <h2 className="Body__heading">{heading}</h2>
  </div>
);

export default Body;
