/* eslint-disable react/prop-types */
import { ChevronDown, ChevronUp } from "components/Icons";
import { observer } from "mobx-react";

const Accordian = ({ items, onClick }) => {
  return (
    <div className="Accordion">
      {items.map((item) => (
        <AccordionItem key={item.key} item={item} onClick={onClick} />
      ))}
    </div>
  );
};

const AccordionItem = ({ item, onClick }) => {
  const handleClick = () => {
    onClick(item.isActive ? null : item);
  };

  // Create a slugified version of the item.heading for the ID
  const id = !!item.heading
    ? item.heading
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "")
    : "";

  return (
    <div key={item.key} className="Accordion__item" id={id}>
      <div className="Accordion__heading">
        <button
          className="Accordion__button"
          onClick={handleClick}
          type="button"
        >
          <div className="Accordian__label">{item.heading}</div>
          <div className="Accordian__icon">
            {item.isActive ? <ChevronUp /> : <ChevronDown />}
          </div>
        </button>
      </div>
      {item.isActive && (
        <div className="Accordion__panel">
          <div className="Accordian__panelContent">{item.component}</div>
        </div>
      )}
    </div>
  );
};

export default observer(Accordian);
