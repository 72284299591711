/* eslint-disable react/prop-types */
const Callout = ({ heading, text }) => (
  <div className="Callout">
    <div className="Callout__content">
      <div className="Callout__heading">{heading}</div>

      <div
        className="Callout__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  </div>
);

export default Callout;
