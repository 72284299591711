import { useEffect, useState } from "react";

const InstagramPost = ({ postUrl, embedCode }) => {
  const [isMounted, setIsMounted] = useState(false);

  const processEmbeds = () => {
    if (typeof window !== "undefined") {
      window.instgrm.Embeds.process();
    }
  };

  useEffect(() => {
    setIsMounted(true);

    const interval = setInterval(() => {
      if (typeof window !== "undefined" && !!window.instgrm) {
        processEmbeds();
        clearInterval(interval);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="InstagramPost">
        <div className="InstagramPost__embed">
          {!!embedCode && (
            <div dangerouslySetInnerHTML={{ __html: embedCode }} />
          )}
        </div>
      </div>
    </>
  );
};

export default InstagramPost;
